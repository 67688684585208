/* eslint-disable no-prototype-builtins */
import jwtDecode from 'jwt-decode';
import { fDateDash } from './formatTime';
import conObj from './connection-assistant';

/**
 * Check if permission code exist on users allowed permissions
 * @param {Array} permission
 * @returns {Boolean}
 */
export function hasPermission(permission) {
  try {
    const accessToken = localStorage.getItem('accessToken');
    if (accessToken) {
      const { perm } = jwtDecode(accessToken);
      let isAuthorized = false;
      if (permission && perm) {
        const permList = JSON.parse(perm);
        if (permList.indexOf(permission) !== -1) {
          isAuthorized = true;
        }
      }
      return isAuthorized;
    }
  } catch (error) {
    return false;
  }

  return false;
}

/**
 * Check if variable is type Object
 * @param {Object} variable
 * @returns Boolean
 */
export const isObject = (variable) => Object.prototype.toString.call(variable) === '[object Object]';

/**
 * Reformat object for sending request data
 * @param {Object} obj
 * @returns Object
 */
export const fRequestData = (obj) => {
  const filtered = {};
  if (!isObject(obj)) return filtered;
  Object.keys(obj).forEach((key) => {
    if (typeof obj[key] !== 'undefined' && obj[key] !== null && obj[key] !== '') {
      if (Object.prototype.toString.call(obj[key]) === '[object Date]') filtered[key] = fDateDash(obj[key]);
      else if (typeof obj[key] === 'object' && obj[key]?.id) filtered[key] = obj[key]?.id;
      else filtered[key] = obj[key];
    }
  });

  return filtered;
};

/**
 * Convert object to array of formatted Object using keys parameter
 * @param {Object} obj
 * @param {Array} keys - Key names to be used where keys[0] = key and keys[1] = value
 * @returns Array Or Null
 */
export const fObjToArrayObj = (obj, keys) => {
  if (!isObject(obj)) return null;

  const arr = [];

  Object.keys(obj).forEach((key) => {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      arr.push({
        [keys[0]]: key,
        [keys[1]]: obj[key],
      });
    }
  });

  return arr;
};

export function mergeObjectsWithMatchingKey(target, source) {
  const result = { ...target };

  Object.keys(source).forEach((key) => {
    if (result.hasOwnProperty(key)) {
      if (
        source[key] !== null &&
        typeof source[key] !== 'undefined' &&
        typeof source[key] === 'object' &&
        !Array.isArray(source[key]) &&
        typeof result[key] === 'object' &&
        !Array.isArray(result[key])
      ) {
        result[key] = mergeObjectsWithMatchingKey(result[key], source[key]);
      } else {
        result[key] = source[key];
      }
    }
  });

  return result;
}

export function urlBase64ToUint8Array(base64String) {
  const padding = '='.repeat((4 - (base64String.length % 4)) % 4);
  const base64 = (base64String + padding).replace(/-/g, '+').replace(/_/g, '/');

  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }

  return outputArray;
}

export function checkIfQCExistToObj(obj, qcText, library, key, idKey) {
  let retVal = null;

  if (!obj?.id && qcText) {
    const isExist = library.find((x) => x[key] === qcText);
    if (isExist && isExist?.[idKey]) {
      retVal = isExist;
    }
  }

  return retVal;
}

export function checkIfQCExistToData(obj, qcText, library, key, idKey) {
  let retVal = obj?.id || qcText;

  if (!obj?.id && qcText) {
    const isExist = library.find((x) => x[key] === qcText);
    if (isExist && isExist?.[idKey]) {
      retVal = parseInt(isExist[idKey], 10);
    }
  }

  return obj?.id ? parseInt(retVal, 10) : retVal;
}

export function getOperatingSystem() {
  const { userAgent } = window.navigator;

  if (/Windows/.test(userAgent)) {
    return 'windows';
  }
  if (/Android/.test(userAgent)) {
    return 'android';
  }
  if (/Mac/.test(userAgent)) {
    return 'mac';
  }
  if (/iPhone|iPad|iPod/.test(userAgent)) {
    return 'ios';
  }
  return ''; // Return empty string for unknown or unsupported OS
}

export function isMobile() {
  const { userAgent } = window.navigator;

  const mobileRegex = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;
  return mobileRegex.test(userAgent);
}

export function objectToUrlParams(obj) {
  const params = new URLSearchParams();

  Object.keys(obj).forEach((key) => {
    if (obj.hasOwnProperty(key) && obj[key] !== null && obj[key] !== undefined && obj[key] !== '') {
      params.append(key, obj[key]);
    }
  });
  return params.toString();
}

export const checkIfNumberExist = async (phoneNumber) => {
  let isNumberExists = false;
  try {
    const response = await conObj.post(`forgot.json`, {
      'user-phone': phoneNumber?.replace(/\D/g, ''),
    });

    if (response.data?.data?.status === 1) isNumberExists = true;
  } catch (error) {
    isNumberExists = false;
  }

  return isNumberExists;
};

export function blobToFile(blob, fileName) {
  return new File([blob], fileName, { type: blob.type });
}

export function resizeInputFilePhoto(file) {
  return new Promise((resolve, reject) => {
    const quality = (Number(process.env?.REACT_APP_IMAGE_COMPRESSION_QUALITY || 80) / 100).toFixed(1);
    const maxsize = Number(process.env?.REACT_APP_IMAGE_COMPRESSION_MAXSIZE || 1024);

    // Ensure it's an image
    if (file.type.match(/image.*/)) {
      console.log(`An image has been loaded. resizing locally with parameters: maxsize=${maxsize} ; quality=${quality}`);
      // Load the image
      const reader = new FileReader();

      reader.onload = function (readerEvent) {
        const image = new Image();
        image.onload = function (imageEvent) {
          if (maxsize > image.width && maxsize > image.height) {
            // console.log(`no resize needed. current image's width=${image.width} ; height=${image.height}`);
            resolve(file);
          } else {
            // Resize the image
            const canvas = document.createElement('canvas');
            const maxSize = maxsize;
            let width = image.width;
            let height = image.height;

            // console.log(`resize needed. current image's width=${width} ; height=${height}`);

            if (width > height) {
              if (width > maxSize) {
                height *= maxSize / width;
                width = maxSize;
              }
            } else if (height > maxSize) {
              width *= maxSize / height;
              height = maxSize;
            }

            // console.log(`final image's width=${width} ; height=${height}`);

            canvas.width = width;
            canvas.height = height;
            canvas.getContext('2d').drawImage(image, 0, 0, width, height);
            // resized
            // dataUrl = canvas.toDataURL('image/jpeg');
            canvas.toBlob(
              (blob) => {
                if (blob) {
                  // console.log(`Resized file size: ${(blob.size / (1024 * 1024)).toFixed(2)} MB`);
                  // console.log(`Original file size: ${(file.size / (1024 * 1024)).toFixed(2)} MB`);
                  const compressedFile = new File(
                    [blob],
                    file.name.replace(/\.[^/.]+$/, '.webp'), // Change extension to .webp
                    {
                      type: 'image/webp',
                      lastModified: Date.now(),
                    }
                  );

                  resolve(compressedFile);
                  // console.log('resize done');
                }
              },
              file.type,
              quality
            );
            // const resizedImage = dataURLToBlob(dataUrl);     //console.log(dataUrl);
          }
        };

        image.src = readerEvent.target.result;
      };

      reader.readAsDataURL(file);
    } else {
      reject(Error('Not an Image'));
    }
  });
}

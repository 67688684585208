import { Box } from "@mui/material";
import { m } from "framer-motion";
import React, { useEffect, useRef, useState } from "react";

const Marquee = ({ text, speed = 100 }) => {
  const textRef = useRef(null);
  const [textWidth, setTextWidth] = useState(0);
  const [containerWidth, setContainerWidth] = useState(0);

  useEffect(() => {
    if (textRef.current) {
      setTextWidth(textRef.current.offsetWidth);
      setContainerWidth(textRef.current.parentElement.offsetWidth);
    }
  }, [text]);

  const totalDistance = textWidth + containerWidth;
  const duration = totalDistance / speed; // Calculate duration based on speed

  return (
    <Box
      sx={{
        overflow: "hidden",
        whiteSpace: "nowrap",
        position: "relative",
        width: "100%",
        display: 'flex',
        alignItems: 'center',
        height: '100%'
      }}
    >
      <Box
      component={m.div}
        ref={textRef}
        style={{
          display: "inline-block",
          whiteSpace: "nowrap",
          position: 'absolute',
        }}
        animate={{ x: [containerWidth, textWidth * -1] }}
        transition={{
          x: {
            repeat: Infinity,
            repeatType: "loop",
            duration,
            ease: "linear",
          },
        }}
      >
        {text}
      </Box>
    </Box>
  );
};

export default Marquee;

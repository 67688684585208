// ----------------------------------------------------------------------

// IF THIS TRANSLATION IS INCORRECT PLEASE IGNORE THIS AS THIS TRANSLATION IS FOR DEMO PURPOSES ONLY
// We are happy if you can help improve the translation by sending an email to support@minimals.cc.

// ----------------------------------------------------------------------

const en = {
  game: `খেলা`,
  games: `খেলাগুলি`,
  sport: `খেলাধারা`,
  center: `কেন্দ্র`,
  mines: `মাইনস`,
  crash: `ক্র্যাশ`,
  lucky_colour: `ভাগ্যবান রঙ`,
  dice: `ডাইস`,
  limbo: `লিম্বো`,
  lucky_gift: `ভাগ্যবান উপহার`,
  all_games: `সমস্ত খেলা`,
  game_history: `খেলার ইতিহাস`,
  coupon_code: `কুপন কোড`,
  telegram: `টেলিগ্রাম`,
  customer_service: `গ্রাহক সেবা`,
  player: `খেলোয়াড়`,
  winner: `বিজয়ী`,
  all_bets: `সমস্ত বাজি`,
  my_bets: `আমার বাজি`,
  name: `নাম`,
  time: `সময়`,
  bet_amount: `বাজির পরিমাণ`,
  prediction: `পূর্বাভাস`,
  payout: `পেআউট`,
  login: `লগইন`,
  register: `রেজিস্টার`,
  username_placeholder: `ব্যবহারকারীর নাম`,
  please_input_your_x: `অনুগ্রহ করে আপনার {{x}} ইনপুট করুন`,
  verification_code: `যাচাইকরণ কোড`,
  press_get_code: `যাচাইকরণ কোড পেতে GET CODE বাটনে চাপুন`,
  otp_sent: `যাচাইকরণ কোড প্রেরিত হয়েছে! চালিয়ে যান নীচের যাচাইকরণ কোড প্রবেশ করুন`,
  your_otp_expire: `আপনার OTP {{x}} মিনিট পর্যন্ত প্রযোজ্য থাকবে`,
  get_code: `কোড পান`,
  didnt_receive: `ম্যাসেজ পাচ্ছেন না?`,
  send_again: `আবার পাঠান`,
  captcha: `ক্যাপচা`,
  forgot_password: `পাসওয়ার্ড ভুলে গেছেন?`,
  minor_note: `এই সাইটে যাওয়ার জন্য নিশ্চিত হন যে আপনি ১৮ বছরের বেশি এবং সম্মত`,
  terms_of_service: `সেবার শর্ত`,
  by_signing_in: 'সাইন ইন বা সাইন আপ করে, আপনি আমাদের সাথে আমাদের',
  dont_have_account: `একাউন্ট নেই?`,
  sign_up: `সাইন আপ`,
  username: `ব্যবহারকারীর নাম`,
  email: `ইমেল`,
  full_name: `পুরো নাম`,
  confirm_18: `আমি নিশ্চিত যে আমি ১৮ বছরের এবং আমি পড়েছি`,
  already_have_account: `ইতিমধ্যে একটি অ্যাকাউন্ট রয়েছে?`,
  share: `শেয়ার`,
  deposit: `আমানত`,
  bonus: `বোনাস`,
  app: `অ্যাপ`,
  load_more: `আরো লোড করুন`,
  details: `বিস্তারিত`,
  helps: `সাহায্য`,
  kyc_policy: `KYC নীতি`,
  privacy_policy: `গোপনীয়তা নীতি`,
  responsible_gaming: `দায়িত্বপূর্ণ গেমিং`,
  terms_and_condition: `শর্তাবলী`,
  faq: `সাধারণ প্রশ্ন`,
  about_us: `আমাদের সম্পর্কে`,
  amount: `পরিমাণ`,
  extra: `অতিরিক্ত`,
  deposit_bonus: `আমানত বোনাস`,
  do_not_participate: `অংশগ্রহণ করবেন না প্রচারে`,
  payment_problem: `পেমেন্ট সমস্যা`,
  click_for_help: `সাহায্যের জন্য ক্লিক করুন`,
  deposit_notes: `আমানত নোট`,
  available_balance: `উপলব্ধ হিসাব`,
  withdraw: `উত্তোলন`,
  withdrawal_notes: `উত্তোলন নোট`,
  withdrawal_note1: `বর্তমানে উত্তোলনের সংখ্যা`,
  withdrawal_note1_1: `ভিপি`,
  withdrawal_note2: `উত্তোলন ফি:`,
  min: `ন্যূনতম:`,
  max: `সর্বাধিক:`,
  opening_times: `খোলার সময়:`,
  withdrawal_note3: `1. ১০ মিনিটের মধ্যে সর্বশ্রেষ্ঠ উত্তোলন সময়`,
  withdrawal_note4: `2. আপনার উত্তোলন তথ্য যথাযথ হলে, যদি উত্তোলন তথ্য ভুল থাকে, তাহলে আপনার অর্থ হারাতে পারে।`,
  withdrawal_note5: `3. আপনার যদি কোনও প্রশ্ন থাকে, তাহলে আমাদের সাথে যোগাযোগ করুন।`,
  notifications: `বিজ্ঞপ্তি`,
  invalid_x: `অবৈধ {{x}}`,
  captcha_not_match: `ক্যাপচা মেলে না`,
  invalid_x_must_be_atleast_y_characters: `অবৈধ {{x}}, অন্তত {{y}} অক্ষর হতে হবে`,
  x_is_required: `{{x}} প্রয়োজন`,
  phone_number: 'ফোন নাম্বার',
  phone_num_x_max: `ফোন নাম্বার সর্বাধিক {{x}} সংখ্যা`,
  email_sent: `ইমেল প্রেরিত, দয়া করে আপনার ইমেল চেক করুন।`,
  enter_valid_email: `দয়া করে একটি বৈধ ইমেল প্রদান করুন।`,
  confirm: `নিশ্চিত করুন`,
  password_atleast_6_characters: `পাসওয়ার্ড অবশ্যই ৬ টি অক্ষরের বেশি হতে হবে।`,
  password_reset_success: `পাসওয়ার্ড রিসেট সাফল্য!.`,
  new_password: `নতুন পাসওয়ার্ড`,
  bank: 'ব্যাংক',
  bank_transfer: `ব্যাংক ট্রান্সফার`,
  bank_name: `ব্যাংকের নাম`,
  bank_branch: `ব্যাংক BSB`,
  pay_id: `পে আইডি`,
  account_name: `অ্যাকাউন্টের নাম`,
  account_number: `অ্যাকাউন্ট নম্বর`,
  drop_or_select: `ফাইল ড্রপ অথবা সিলেক্ট করুন`,
  drop_files_here: `ফাইল এখানে পোঁয়ান`,
  transfer_to: `ট্রান্সফার টু`,
  upload_receipt: `রসিদ আপলোড করুন`,
  select_bonus: `বোনাস নির্বাচন করুন`,
  no_promotion: `কোনও প্রমোশন নেই`,
  please_upload_receipt: `দয়া করে রসিদ আপলোড করুন`,
  bank_account: `ব্যাংক অ্যাকাউন্ট`,
  add_account: `অ্যাকাউন্ট যোগ করুন`,
  account_holder_name: `অ্যাকাউন্ট হোল্ডারের নাম`,
  name_of_the_account_holder: `অ্যাকাউন্ট হোল্ডারের নাম`,
  fill_in_bank_account: `ব্যাংক অ্যাকাউন্ট পূরণ করুন`,
  withdraw_information: `উত্তোলন তথ্য`,
  note: `নোট`,
  amount_withdrawn: `উত্তোলন হয়েছে`,
  amount_no_exceed_balance: `উত্তোলনের পরিমাণ উপলব্ধ হিসাবের অধিক হওয়া উচিত নয়`,
  minimum_withdraw_amount_is_x: `ন্যূনতম উত্তোলন পরিমাণ {{x}}`,
  transaction_history: `লেনদেনের ইতিহাস`,
  date_from: `তারিখ থেকে`,
  date_to: `তারিখ পর্যন্ত`,
  account_name_must_be_4: `অ্যাকাউন্ট নাম ৪ থেকে বেশি হতে হবে`,
  account_number_must_be_x: `অ্যাকাউন্ট নম্বর ১০-১৪ অক্ষরের হতে হবে`,
  minimum_length_is_x: `ন্যূনতম দৈর্ঘ্য {{x}}`,
  maximum_length_is_x: `সর্বাধিক দৈর্ঘ্য {{x}}`,
  payment_method: `পেমেন্ট পদ্ধতি`,
  mode: `মোড`,
  no_bank_account: `আপনার কোনও অ্যাকাউন্ট উত্তোলনের জন্য নেই, এখন যোগ করুন`,
  please_select_bank: `দয়া করে একটি ব্যাংক অ্যাকাউন্ট নির্বাচন করুন`,
  bet_history: `বাজি ইতিহাস`,
  game_provider: `গেম প্রদায়ক`,
  bet_count: `বাজি গণনা`,
  win_loss: `জয়/ব্যতিত`,
  rebate: `রিবেট`,
  turnover: `টার্নওভার`,
  game_category: `গেম বিভাগ`,

  you_have_x_unread_messages: `আপনার {{x}} অপঠিত বার্তা আছে`,
  deposit_amount_between_x_and_y: `আমানতের পরিমাণ {{x}} এবং {{y}} এর মধ্যে হতে হবে`,
  minimum_amount_is_x_y: `ন্যূনতম পরিমাণ {{x}} {{y}}`,

  // all
  all: `সব`,
  title: `শিরোনাম`,
  date: `তারিখ`,
  type: `ধরণ`,
  status: `অবস্থা`,
  remark: `মন্তব্য`,
  category: `বিভাগ`,
  please_select_x: `অনুগ্রহ করে {{x}} নির্বাচন করুন`,
  save: `সংরক্ষণ করুন`,

  change_password: `পাসওয়ার্ড পরিবর্তন করুন`,
  current_password: `বর্তমান পাসওয়ার্ড`,
  confirm_password: `পাসওয়ার্ড নিশ্চিত করুন`,
  submit: `জমা দিন`,
  password_changed: `পাসওয়ার্ড পরিবর্তিত হয়েছে`,
  x_must_be_y_characters: `{{x}} অন্তত {{y}} টি অক্ষর হতে হবে`,
  reset_password: `পাসওয়ার্ড রিসেট করুন`,
  casino: `ক্যাসিনো`,
  slots: `স্লট`,
  live_casino: `লাইভ ক্যাসিনো`,
  fishing: `মাছ ধরার খেলা`,
  sports: `খেলা`,
  cards: `কার্ড`,
  clear: `স্পষ্ট`,
  search: `অনুসন্ধান`,
  new: `নতুন`,
  logout: `লগ আউট`,
  choose_date: `তারিখ নির্বাচন করুন`,
  select_both_date: `তারিখ থেকে এবং পর্যন্ত উভয় নির্বাচন করুন`,
  from: `থেকে`,
  to: `পর্যন্ত`,
  apply: `প্রয়োগ করুন`,
  promotions: `প্রচার`,
  learn_more: `আরও জানুন`,
  password_doesnt_match: `পাসওয়ার্ড মেলে না`,
  cancel: `বাতিল`,
  confirm_to_pay: `পেমেন্ট নিশ্চিত করুন`,
  deposit_amount: `আমানতের পরিমাণ`,
  loading_please_wait: `লোড হচ্ছে, অনুগ্রহ করে অপেক্ষা করুন`,
  account: `অ্যাকাউন্ট`,
  profile: `প্রোফাইল`,
  bank_list: `ব্যাংক তালিকা`,
  in_maintenance: `রক্ষণাবেক্ষণে`,
  birthday: `জন্মদিন`,
  birthday_bonus: `বোনাস পেতে আপনার জন্মদিন দিন! 🎉🎁`,
  referral_code: `রেফারেল কোড`,
  referral: `রেফারেল`,
  my_referral: `আমার রেফারেল`,
  my_claim: `আমার দাবি`,
  claim_profit: `লাভ দাবি`,
  available_profit: `উপলব্ধ লাভ`,
  claimed_profit: `দাবিত লাভ`,
  all_time_profit: `সমস্ত সময় লাভ`,
  referral_note_on_x: `প্রতিটি বন্ধু {{x}} খেলায় খেলার সময় লাভ করুন`,
  tier: `টিয়ার`,
  commission: `কমিশন`,
  referred_member: `রেফারড সদস্য`,
  profit_earned: `লাভ অর্জন করা`,
  account_information: `অ্যাকাউন্ট তথ্য`,
  invite_friends: `বন্ধুদের আমন্ত্রণ করুন`,
  how_it_work: `এটা কিভাবে কাজ করে?`,
  invite_note: `আপনার রেফারেল কোড ব্যবহার করে আপনার বন্ধু সাইন আপ করলে এক্সক্লুসিভ রেফারেল বোনাস পান!`,
  share_via: `মাধ্যমে শেয়ার করুন`,
  referral_link: `রেফারেল লিংক`,
  total_profit: `মোট লাভ`,
  copied: `অনুলিপি`,
  nothing_to_claim: `দাবি করার কিছু নেই`,
  claim_history: `দাবি ইতিহাস`,
  downline: `ডাউনলাইন`,
  last_x_digit_bank_ref_number: `ব্যাংক রেফারেন্স নম্বরের শেষ {{x}} টি অংশ`,
  bank_ref_number_validation_x: `ব্যাংক রেফারেন্স নম্বর প্রয়োজন এবং এটি {{x}} অক্ষর দৈর্ঘ্য হতে হবে`,
  sort: 'সাজান',
  login_directly: `সরাসরি লগইন করুন`,
  our_game_provides: `আমাদের গেম প্রদান করে`,
  refer_and_reward: `রেফার এবং পুরস্কার`,
  linked_successfully_x: `{{x}} সাথে সফলভাবে লিঙ্ক করা হয়েছে! অনুগ্রহ করে আপনার অ্যাকাউন্টটি প্রথমবারের জন্য নিবন্ধন করুন`,
  more_x_of_y: `{{x}}/{{y}} এর বেশি`,
  default: `ডিফল্ট`,
  promotion: `প্রচার`,
  daily_mission: 'ডেইলি মিশন',
  agent: 'এজেন্ট',
  contact_us: 'যোগাযোগ করুন',
  download: 'ডাউনলোড',
  cash_claim: 'নগদ দাবি',
  demo: 'ডেমো',
  favorites: 'প্রিয়',
  popular: 'জনপ্রিয়',
  claim_bonus: `বোনাস দাবি`,
  insufficient_balance: `অপর্যাপ্ত হিসাব`,
  currently_displaying: 'বর্তমানে {{x}} {{y}} খেলা {{z}} হইছে',
  all_x: 'সব {{x}}',
  no_results: 'কোনও ফলাফল পাওয়া যায়নি',
  search_games: 'খেলা অনুসন্ধান করুন',
  please_scan_using_your: `অনুগ্রহ করে আপনার ব্যবহার করে স্ক্যান করুন`,
  vip_level: `VIP স্তর`,
  bonus_direct: `বোনাস সরাসরি`,
  rebate_level: `রিবেট স্তর`,
  vip_header: `ভিক্টর৮৮আউসের স্লট মেশিন, বোর্ড এবং কার্ড গেম, ফিশিং, ভিডিও, খেলার দাঁড়ি এবং লটারি গেমে কোনও প্রতিযোগিত্মার জন্য দেয়া টাকা স্থায়ীভাবে যোগ করা হবে। বৈধ বাজি = স্থায়ী আয় এবং টাকার ভাগ্য!`,
  vip_example: `
  উদাহরণ 1: সদস্যরা যারা এক মিলিয়ন টাকার সংগ্রহে পৌঁছেছেন, তারা VIP লেভেল 2 অধিগ্রহণ করে R$210 এবং 3.50% রিবেট বোনাস পাবেন। যখন তারা 6 মিলিয়ন টাকার সংগ্রহে পৌঁছেছেন, তারা VIP লেভেল 3 অধিগ্রহণ করে R$800 এবং 4% রিবেট পাবেন`,
  activity_rules: `কার্যকলাপের নিয়মগুলি:`,
  activity_rules_1: `1. সমস্ত বোনাস অবশ্যই x3 উইনওভার থাকতে হবে উত্তোলনের জন্য`,
  activity_rules_2: `2. দয়া করে সময়ের মধ্যে রিবেট বোনাস দাবি করুন, আপনি দেরি করলে এটি পরিত্যাগ হিসাবে গণ্য হবে;`,
  activity_rules_3: `3. সদস্যদের কার্যকলাপে অংশগ্রহণ স্বয়ংক্রিয়ভাবে সিস্টেম দ্বারা গণ্য হয়, যদি কোনও আপত্তি থাকে, তবে TCL99BDT এর গবেষণার ফলাফল অগ্রাধিকার করবে;`,
  activity_rules_4: `4. আপনি যদি আপনার সদস্য অ্যাকাউন্ট / পাসওয়ার্ড ভুলে গেছেন, তবে "24 ঘণ্টা অনলাইন কাস্টমার সার্ভিস" এ যোগাযোগ করুন আপনার অ্যাকাউন্টের তথ্য পুনরুদ্ধার করার জন্য;`,
  activity_rules_5: `5. TCL99BDT এর কাছে এই প্রচারণা পরিবর্তন, বন্ধ বা বাতিল করতে অধিকার রয়েছে।`,
  activity_rules_6: `6. ফ্রি গেম সংরক্ষণ করবেন না, ফ্রি গেম কিনুন`,
  activity_rules_7: `7. কোনও বোনাসের সাথে যুক্ত করা যাবে না`,
  activity_rules_8: `8. প্রতি ডিপোজিটের সর্বাধিক উত্তোলন R$100,000`,
  event_instructions: 'ইভেন্ট নির্দেশিকা',
  agent_p1: 'অ্যাপ এবং সোশ্যাল মিডিয়াতে আপনার অনন্য লিঙ্ক শেয়ার করুন',
  agent_p2:
    'ফেসবুক, ইনস্টাগ্রাম, টিকটক, টেলিগ্রাম, WhatsApp, YouTube ইত্যাদির মধ্যে হারিয়ে প্রত্যেককে আমন্ত্রণ জানান, যে সদস্যরা আপনার একক লিঙ্কের মাধ্যমে নিবন্ধিত হবেন। কিন্তু লক্ষ্য রাখবেন যে, আপনি যারা নিবন্ধন করছেন, তাদের মনোনীত ও বৈধ হতে হবে।',
  agent_step1:
    'আপনার প্রথম আমন্ত্রণ প্রারম্ভিক জমা সফল হলে, আপনি R$5 এর বোনাস জিততে পারেন। উদাহরণস্বরূপ, আপনি যদি প্রথম জমা করার জন্য 10 জনকে আমন্ত্রণ জানান, তাহলে আপনি R$50 অর্জন করতে পারেন। আপনি যত বেশি লোককে আমন্ত্রণ জানাবেন, ততই বেশি বোনাস পাবেন। আপনি আপনার ইতিহাস দেখতে এবং প্রমোশন পৃষ্ঠায় নিবন্ধন করতে পারেন এবং উচ্চ কমিশন প্রাপ্ত করতে পারেন।',
  agent_step2:
    'আপনার অতিথিরা R$1 বা তার বেশি বাজি করলে, আপনি ইতিমধ্যে উচ্চ কমিশন উপভোগ করতে পারেন! আপনি জীবনকালীন কমিশনে পর্যাপ্ত উপভোগ করতে পারেন এবং জীবনের একটি আমন্ত্রণ! সিস্টেম পূর্বদিনের কমিশনগুলি পরের দিন সকাল 7 টায় সমন্বয় করবে।',
  agent_step2_1: 'কীভাবে পাবেন: লগইন - আমন্ত্রণ প্রচার - কমিশন সংগ্রহ করুন',
  agent_table1: 'স্লট, ফিশিং এবং ব্লকচেনের বেটিং কমিশন হার নিম্নের মতো:',
  agent_table1_1: 'গ্রাহক টপআপ 20 বেট 200',
  person: 'ব্যক্তি',
  agent_table2_title1: 'সদস্যের বেটিং',
  agent_table2_title2: 'শুধু স্লট গেম',
  agent_table2_example:
    'উদাহরণ: আপনার 100 সদস্য আছে এবং দৈনিক মোট বাস্তব মূল্য ধারণ করা হয় 1 মিলিয়ন রিয়াল। সেই দিন আপনি পাবেন: 1,000,000 x 1.20% 12,000 রিয়াল। মাসিক আয় 100,000 অত্যন্ত সহজেই অর্জন করা যায়।',
  reminder: 'মন্তব্য',
  agent_reminder:
    'একই IP, একই ডিভাইস, নাম, ব্যাংক কার্ড এবং অন্যান্য তথ্য সহ একই সদস্য অ্যাকাউন্ট একবার অংশগ্রহণ করতে পারে, এবং যে কেউ যে বিধি ভঙ্গ করলে এই বোনাস থেকে সুবিধা পাবে না। বা গ্রুপ সামগ্রিকভাবে বাতিল হবে এবং অ্যাকাউন্টগুলি স্থায়ীভাবে বরফাত হবে',
  agent_step3_1:
    'সদস্যদের কার্যকলাপে অংশগ্রহণ স্বয়ংক্রিয়ভাবে সিস্টেম দ্বারা গণ্য হয়, যদি কোনও আপত্তি থাকে, তবে TCL99BDT এর গবেষণার ফলাফল অগ্রাধিকার করবে',
  agent_step3_2:
    'আপনি যদি আপনার সদস্য অ্যাকাউন্ট / পাসওয়ার্ড ভুলে গেছেন, তবে আমাদের 24 ঘণ্টা কাস্টমার সার্ভিস দলের সাহায্য নিতে আপনার অ্যাকাউন্টের তথ্য পুনরুদ্ধার করার জন্য যোগাযোগ করুন',
  agent_step3_3: 'TCL99BDT এর অধিকার রয়েছে এই প্রচারণাটি পরিবর্তন, বন্ধ বা বাতিল করতে',
  agent_step3_4: 'অ্যাজেন্ট পারফর্মে প্রাপ্ত কমিশন বোনাস টার্ম ক্যাশ বা ওয়ালেটে ক্রেডিটের অবস্থানে',
  agent_step3_5: 'ফ্রি গেম সংরক্ষণ করবেন না, ফ্রি গেম কিনুন',
  agent_step3_6: 'কোনও বোনাস এবং ডিপোজিটের সাথে যুক্ত করা যাবে না',
  agent_step3_7: 'শুধুমাত্র স্লট গেমের জন্য প্রযোজ্য',
  promo_concluded: 'প্রমো শেষ হয়েছে! কাল আবার পর্যন্ত দেখুন!',
  can_only_claim: '{{x}} থেকে {{y}} মাত্র ব্রাজিল সময়ে বোনাস দাবি করা যাবে',
  please_login: 'বোনাস প্রাপ্ত করতে প্রথমে লগইন করুন!',
  back_to_home: 'হোমে ফিরে যান',
  bonus_claimed: 'বোনাস ইতিমধ্যে দাবি করা হয়েছে!',
  terms_n_conditions: `শর্তাদি`,
  claim_now: `এখন দাবি করুন`,
  the_activity_x_in: `কার্যকলাপ {{x}} মধ্যে`,
  starts: `শুরু হয়েছে`,
  ends: `শেষ হয়েছে`,
  list_of_winners: `জয়ীদের তালিকা`,
  days: `দিন`,
  hours: `ঘন্টা`,
  minutes: `মিনিট`,
  seconds: `সেকেন্ড`,
  total_amount_received: `পুরোটাকার মোট প্রাপ্তবত্তা`,
  deposit_fee: `জমা ফি`,
  get: 'পাওয়া',
  apply_now: `এখন প্রয়োগ করুন`,
  number_exists: 'এই ফোন নম্বরটি ইতিমধ্যে নিবন্ধিত!',
  number_not_exists: 'এই ফোন নম্বরটি নিবন্ধিত নেই!',
  payment_draft:
    'আপনি যদি নিশ্চিত হন যে আপনি ইতিমধ্যে পেয়ে গেছেন, তবে দয়া করে অপেক্ষা করুন কারণ পেমেন্টটি এখনও প্রসেসিং হতে পারে',
  my_downline: 'আমার নিম্নলিখিত',
  downline_name: 'নিম্নলিখিত নাম',
  date_joined: 'যোগদানের তারিখ',
  deposit_amount_x_days: 'জমা পরিমাণ ({{x}} দিন)',
  welcome_to_bk: 'স্বাগতম TCL99BDT এ',
  dont_have_an_account: `অ্যাকাউন্ট আছে না? `,
  fill_up_here: 'এখানে পূরণ করুন',
  currency_and_language: 'মুদ্রা এবং ভাষা',
  language: 'ভাষা',
  deposit_channel: `জমা চ্যানেল`,
  deposit_details: `জমা বিশদ`,
  bonus_amount: `বোনাস পরিমাণ`,
  main_wallet: `মুখ্য ওয়ালেট`,
  withdraw_details: `উত্তোলন বিশদ`,
  withdraw_amount: `উত্তোলন পরিমাণ`,
  password: 'পাসওয়ার্ড',
  bank_details: `ব্যাংকের বিশদ`,
  add: `যোগ করুন`,
  bank_holder_name: `ব্যাংক হোল্ডার নাম`,
  bank_account_number: `ব্যাংক অ্যাকাউন্ট নাম্বার`,
  mandatory_field: `এটি একটি প্রয়োজনীয় ক্ষেত্র`,
  home: `হোম`,
  password_not_match: 'পাসওয়ার্ড মেলে না',
  payment_methods: 'পেমেন্ট পদ্ধতি',
  customer_support: 'গ্রাহক সমর্থন',
  chat_now: 'এখন চ্যাট করুন',
  certifications: 'সার্টিফিকেশন',
  community_website: 'সম্প্রদায় ওয়েবসাইট',
  gaming_license: 'গেমিং লাইসেন্স',
  footer_title_1: 'TCL99BDT অনলাইন ক্যাসিনো পরিচিতি',
  footer_paragraph_1:
    'উত্তেজনাময় অনলাইন ক্যাসিনো জগতের আপনার বিশ্বস্ত ও বাংলাদেশের সেরা অনলাইন ক্যাসিনো TCL99BDT তে আপনাকে স্বাগতম। এখানে TCL99BDT তে, আমরা আপনাকে একটি অতুলনীয় গেমিং অভিজ্ঞতা প্রদানে বদ্ধপরিকর যা সর্বোচ্চ নিরাপত্তা, রোমাঞ্চকর গেমিং অভিজ্ঞতা এবং নতুন নতুন পুরস্কারের সমন্বয় করে। আসুন জেনে নেই আমাদের বিশেষত্ব এবং কেন অনলাইন ক্যাসিনোর জগতে আমরাই আপনার চূড়ান্ত গন্তব্য।',
  footer_title_2: 'নিরাপত্তা ও বিশ্বস্ততা',
  footer_paragraph_2:
    'TCL99BDT তে আপনার নিরাপত্তা আমাদের সর্বোচ্চ অগ্রাধিকার। আমরা আপনার ব্যক্তিগত এবং আর্থিক তথ্য সুরক্ষিত রাখার স্বার্থে অত্যাধুনিক এনক্রিপশন প্রযুক্তি ব্যবহার করি, যাতে আপনি নিশ্চিন্তে আপনার গেমিং অভিজ্ঞতা উপভোগ করতে পারেন। আমাদের প্লাটফর্মটি সম্পূর্ন লাইসেন্সপ্রাপ্ত। প্রতিটি গেমের মান এবং স্বচ্ছতা নিশ্চিত করার জন্য কঠোর মাননিয়ন্ত্রণের মাধ্যমে পরিচালিত হয়।',
  footer_title_3: 'রোমাঞ্চকর গেম নির্বাচন',
  footer_paragraph_3:
    'আমাদের অসংখ্য গেমিং কালেকশনের সাথে একটি আনন্দদায়ক যাত্রা শুরু করার জন্য প্রস্তুত হন। স্লট, রুলেট এবং ব্ল্যাকজ্যাকের মতো ক্ল্যাসিক কালেকশন থেকে শুরু করে অত্যাধুনিক লাইভ ডিলার অভিজ্ঞতা পর্যন্ত আমরা প্রতিটি খেলোয়ারের জন্যই গেম অফার করে থাকি। আমাদের গেমগুলো শীর্ষস্থানীয় সফটওয়্যার প্রোভাইডার দ্বারা ডেভেলপ করা, যা আপনাকে অত্যাধুনিক গ্রাফিক্স, নিরবচ্ছিন্ন গেমপ্লে এবং রোমাঞ্চকর বোনাসসমূহ প্রদান করে থাকে।',
  footer_title_4: 'রোমাঞ্চকর বোনাস ও প্রমোশন',
  footer_paragraph_4:
    'TCL99BDT এর একজন সম্মানিত সদস্য হিসেবে আপনি পাবেন অসংখ্য রোমাঞ্চকর বোনাস ও প্রমোশন। স্বাগতম বোনাসগুলো আপনার প্রাথমিক ডিপোজিটকে চলমান প্রমোশন এবং লয়ালিটি রিওয়ার্ডের সম্ভাবনাকে বাড়িয়ে তোলে। আমরা সাধারণত আমাদের খেলোয়াড়দের পুরস্কৃত করতে বিশ্বাস করি। অতিরিক্ত পুরষ্কারের সাথে আপনার গেমিং অভিজ্ঞতাকে বাড়িয়ে তোলার জন্য নতুন নতুন অফারগুলো জানতে নিয়মিত আমাদের প্রমোশন পেইজে চোখ রাখুন।',
  footer_title_5: 'সহজে ব্যাবহারযোগ্য ইন্টারফেস',
  footer_paragraph_5:
    'খেলোয়াড়দের সুবিধার কথা মাথায় রেখে আমাদের প্লাটফর্মটি একটি সহজে ব্যাবহারযোগ্য ইন্টারফেস দ্বারা ডিজাইন করা হয়েছে। ডেস্কটপ, মোবাইল বা যেকোন ট্যাবলেট ডিভাইসেই আপনি বিচরন করুন, আপনি পাবেন একটি নিরবচ্ছিন্ন গেমপ্লে এবং ঝামেলাহীন দিকনির্দেশনা। আমাদের অত্যাধুনিক ইন্টারফেস আপনার চাহিদাকে সফলভাবে পূরন করতে সক্ষম-মজা করুন এবং বড় বড় উইন করুন!',
  footer_title_6: '২৪/৭ গ্রাহক সেবা',
  footer_paragraph_6:
    'সাহায্য প্রয়োজন? আমাদের অভিজ্ঞ গ্রাহক সেবা টিম আপনার যেকোন সমস্যার সমাধানের জন্য সর্বদা প্রস্তুত। একাউন্ট নিবন্ধন, পেমেন্ট সংক্রান্ত সমস্যা অথবা গেমের নিয়মকানুন, সবক্ষেত্রেই আমাদের বন্ধুসুলভ এজেন্ট আপনাকে সহায়তা করার জন্য প্রস্তুত। লাইভ চ্যাট, হোয়াটসঅ্যাপ, ইমো বা টেলিগ্রামের মাধ্যমে আমাদের সাথে যোগাযোগ করুন এবং আমরা আপনার গেমিং অভিজ্ঞতাকে মসৃণ এবং উপভোগ্য করে তুলতে বদ্ধপরিকর।',
  footer_title_7: 'দায়িত্বশীল গেমিং',
  footer_paragraph_7:
    'আমরা দায়িত্বশীল গেমিংয়ের চর্চা এবং আমাদের খেলোয়াড়দের নিরাপত্তা নিশ্চিত করতে সর্বদা প্রতিশ্রুতিবদ্ধ। আপনার গেমিং কার্যক্রমকে দায়িত্বশীলতার সাথে ব্যবস্থাপনার জন্য আমাদের প্লাটফর্ম বিভিন্ন সরঞ্জাম ও রিসোর্স অন্তর্ভুক্ত করেছে। ডিপোজিট লিমিটকে স্ব-নিয়ন্ত্রিণ সেটিংয়ে নির্ধারণের মাধ্যমে আমরা আপনার গেমিং অভ্যাস নিজের নিয়ন্ত্রণে রেখে আমাদের প্লাটফর্ম উপভোগ করার সুযোগ দিয়েছি।',
  footer_title_8: 'বাংলাদেশের অনলাইন ক্যাসিনো লাইসেন্সের সাথে সম্পূর্ণ সহাবস্থানকারী',
  footer_paragraph_8:
    'বাংলাদেশে আমাদের TCL99BDT প্রিমিয়ার ক্যাসিনো প্ল্যাটফর্মের সাথে অনলাইন গেমিংয়ের উত্তেজনা অনুসন্ধান করুন। ক্লাসিক স্লট থেকে শুরু করে অত্যাধুনিক লাইভ ডিলার অভিজ্ঞতা পর্যন্ত রোমাঞ্চকর গেমের সুশৃঙ্খল বিন্যাস আবিষ্কার করুন। নতুন নতুন বোনাস এবং অতুলনীয় বিনোদনের জন্য এখনি জয়েন করুন আমাদের সাথে। দায়িত্বশীলতার সাথে খেলুন। ১৮+ বছর বয়সীদের জন্য প্রযোজ্য। সাধারন নিয়ম ও শর্তাবলী প্রযোজ্য।',
  footer_title_9: 'TCL99BDT সর্বোত্তম অনলাইন ক্যাসিনো অভিজ্ঞতা অফার করে',
  footer_paragraph_9:
    'আপনার বিশ্বস্ত ও বাংলাদেশের সেরা অনলাইন ক্যাসিনো TCL99BDT এর সাথে অনলাইন গেমিং বিনোদনের চূড়ান্ত অভিজ্ঞতা নিন। নিরাপত্তা, উত্তেজনা এবং বিভিন্ন বোনাসের সাথে আপনার গেমিং অভিজ্ঞতাকে অনন্য উচ্চতায় নিয়ে যাওয়ার জন্য আমরা সর্বদাই আপনার সাথে আছি। বাংলাদেশের বিচক্ষণ খেলোয়াড়দের কাছে কেন আমরাই প্রথম পছন্দ সেটা জানার জন্য আজই আমাদের সাথে যোগ দিন। চলুন, এখনি খেলা শুরু করুন!',
  footer_copyright: 'কপিরাইট © 2024 TCL99BDT. সমস্ত অধিকার সংরক্ষিত।',
  currency: 'মুদ্রা',
  fill_up_password_here: 'এখানে পাসওয়ার্ড পূরণ করুন',
  betting_records: 'বেটিং রেকর্ড',
  turn_over: 'টার্ন ওভার',
  transfer_records: 'ট্রান্সফার রেকর্ড',
  transaction_records: 'লেনদেন রেকর্ড',
  funds: 'তহবিল',
  history: 'ইতিহাস',
  claim_voucher: 'ভাউচার দাবি করুন',
  check_in_lucky: 'চেক-ইন / ভাগ্যশালী চাকা',
  personal_info: 'ব্যক্তিগত তথ্য',
  inbox_messages: 'ইনবক্স মেসেজ',
  referral_bonus: 'রেফারেল বোনাস',
  support: '24/7 সমর্থন',
  whatsapp: 'WhatsApp',
  facebook: 'ফেসবুক',
  downloads: 'ডাউনলোড',
  download_android: 'অ্যান্ড্রয়েড ডাউনলোড করুন',
  overview: 'ওভারভিউ',
  referral_program: 'রেফারেল প্রোগ্রাম',
  summary: 'সংক্ষিপ্তসার',
  referral_report: 'রেফারেল রিপোর্ট',
  redeem_history: 'রিডিম হিস্ট্রি',
  commission_to_redeem: 'রিডিমের জন্য কমিশন',
  today_commission: 'আজকের কমিশন',
  this_month_commission: 'এই মাসের কমিশন',
  referral_tier: 'রেফারেল টিয়ার',
  total: 'মোট',
  share_now: 'এখন ভাগাড়',
  back: 'পিছনে',
  complete: 'সম্পূর্ণ',
  next: 'পরবর্তী',
  c_bet_amount: 'বেট পরিমাণ',
  c_rebate: 'রিবেট',
  c_turnover: 'টার্নওভার',
  c_amount: 'পরিমাণ',
  c_bonus: 'বোনাস',
  c_remarks: 'মন্তব্য',
  level: 'স্তর',
  no_promotions: 'কোন প্রচার নেই',
  dont_show_again: 'আবার দেখাবেন না',
  close: 'বন্ধ করুন',
  crash_game: `ক্র্যাশ গেম`,
  maximum_withdraw_amount_is_x: `সর্বাধিক উত্তোলনের পরিমাণ হল {{x}}`,
  no_balance: `ব্যালেন্স নেই`,
  no: `না`,
  optional: `অপশনাল`,
  network_error: `নেটওয়ার্ক ত্রুটি`,
  game_providers: `গেম প্রদানকারী`,
  sub_filters: `সাব ফিল্টার`,
  balance: 'ভারসাম্য',
  requirement: 'প্রয়োজনীয়তা',
  deposit_from_bank: 'ব্যাংক থেকে জমা',
  upgrade_bonus: 'আপগ্রেড বোনাস',
  monthly_bonus: 'মাসিক বোনাস',
  weekly_bonus: 'সাপ্তাহিক বোনাস',
  vip_levels: `VIP স্তর`,
  before_that: 'তার আগে',
  verified_by_play_protect: `Play Protect দ্বারা ভেরিফাইড`,
  reviews: `২৬কে রিভিউস`,
  reviews_value: `৪মিলিয়ন+`,
  rated_for: `বয়স ১৮+`,
  install: `ইনস্টল`,
  add_to_wishlist: `পছন্দ তালিকায় যোগ করুন`,
  about_this_app: `অ্যাপ সম্পর্কে`,
  app_description_1: `"বাংলাদেশের সেরা অনলাইন ক্যাসিনো প্ল্যাটফর্ম TCL99BDT-এ আপনাকে স্বাগতম! আমাদের এখানে রয়েছে উত্তেজনাপূর্ণ গেমিং অভিজ্ঞতা অর্জন ও বড় বড় জয়ের বিশাল সুযোগ। আপনি ক্লাসিক ক্যাসিনো গেম অথবা আধুনিক উচ্চ-স্টেকসহ যে গেমেরই অনুরাগী হন না কেন, আমরা প্রত্যেকের জন্যই অফার করি। আপনার মোবাইল ডিভাইসের মাধ্যমে সহজেই জিলি, স্লট, পিজি, লাইভ ক্যাসিনো, ক্রেজি টাইম, রুলেট, পোকার এবং আরও অনেক বিস্তৃত গেমের রোমাঞ্চকর অভিজ্ঞতা নিন।"`,
  app_description_2: `TCL99BDT সর্বদা আপনার নিরাপত্তা এবং গেমিং অভিজ্ঞতাকে অগ্রাধিকার দেয়। আমাদের প্ল্যাটফর্মটি সম্পূর্ণ লাইসেন্সপ্রাপ্ত এবং নিরাপদ, সর্বদা বৈধ খেলা এবং দায়িত্বশীল গেমিং নিশ্চিত করে। আমরা আপনার ব্যক্তিগত তথ্য রক্ষা করতে উন্নত প্রযুক্তি ব্যবহার করি এবং দ্রুত ডিপোজিট, দ্রুত উত্তোলন এবং ২৪ ঘন্টা গ্রাহক সহায়তাসহ একটি উন্নতমানের গেমিং অভিজ্ঞতা প্রদান করি।`,
  why_choose_brand: `কেন TCL99BDT পছন্দ করবেন?`,
  reason_1: `শীর্ষস্থানীয় গেম প্রোভাইডার থেকে সংগৃহীত বিশাল গেম সম্ভার`,
  reason_2: `সহজ দিকনির্দেশনাসহ ব্যবহারকারী-বান্ধব ইন্টারফেস`,
  reason_3: `আর্কষণীয় বোনাস এবং প্রমোশন রয়েছে শুধুমাত্র আপনার জন্য`,
  reason_4: `দ্দ্রুত এবং নিরাপদ লেনদেনের জন্য নিরাপদ অর্থপ্রদানের সুবিধা`,
  reason_5: `আপনার পছন্দ অনুসারে বাংলা এবং ইংরেজি ভাষা নির্বাচনের সুবিধা`,
  app_description_3: `আজই TCL99BDT তে রেজিস্ট্রেশন করুন এবং উপভোগ করুন বাংলাদেশের সেরা অনলাইন ক্যাসিনোর অভিজ্ঞতায়! আপনি গেমিং রোমাঞ্চ বা বড় জয় যেটাই প্রত্যাশা করুন না কেন, TCL99BDT হল আপনার সীমাহীন গেমিং অভিজ্ঞতা ও বিনোদনের প্রবেশদ্বার।`,
  app_download: `অ্যাপ ডাউনলোড`,
  download_message: `আজ আমাদের অ্যাপ ডাউনলোড করুন!`,
  update_kyc: 'KYC আপডেট করুন',
  kyc_verification: 'কেওয়াইসি যাচাইকরণ',
  ic_number: 'আইসি নম্বর',
  nid_number: 'এনআইডি নম্বর',
  ic_front: 'আইসি সামনে',
  nid_front: 'এনআইডি ফ্রন্ট',
  nid_back: 'এনআইডি পেছন অংশ',
  selfie_video_with_ic: 'আইসির সাথে সেলফি ভিডিও',
  selfie_video_with_nid: 'এনআইডি সহ সেলফি ভিডিও',
  kyc_submitted: 'কেওয়াইসি জমা দেওয়া হয়েছে',
  file_too_large: 'ফাইলটি খুব বড়। সর্বাধিক অনুমোদিত আকার {{x}} এমবি',
  limit_your_vid_to_5: 'আপনার ভিডিও ৫ সেকেন্ডের মধ্যে সীমাবদ্ধ রাখুন',
  camera_access_denied:
    'ক্যামেরা অ্যাক্সেস নিষিদ্ধ। অনুগ্রহ করে আপনার ব্রাউজারের সেটিংসে ক্যামেরার অনুমতি সক্রিয় করুন।',
  camera_not_available:
    'কোন ক্যামেরা সনাক্ত করা যায়নি। অনুগ্রহ করে একটি ক্যামেরা সংযুক্ত করুন বা আপনার ডিভাইসের সেটিংস চেক করুন।',
  selfie_photo_with_nid: 'NID এর সাথে সেলফি',
  enter_text_on_the_left: 'বাম দিকে পাঠ্য লিখুন',
  otp_sent_enter_code_password:
    'যাচাইকরণ কোড পাঠানো হয়েছে! এগিয়ে যেতে নিচে কোড এবং আপনার নতুন পাসওয়ার্ড লিখুন দয়া করে',
  transaction_id: 'লেনদেন আইডি',
  transaction_id_must_be_x: 'লেনদেন আইডি প্রয়োজন এবং কমপক্ষে {{x}} অক্ষর দৈর্ঘ্য হতে হবে',
  leave_empty_if_same_as_previous: 'আগের মতো থাকলে খালি ছেড়ে দিন',
};

export default en;
